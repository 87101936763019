import { version } from './version';

export const environment = {
  BASE_URL: 'https://decsaobernardodocampo.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3548708',
  schema: 'pbi_dte_sbc',
  groupId: 'e08f6376-7521-4bfe-a597-588d4a910b89',
  analiseGeral: '16c95a7b-9214-4e8e-aef7-541098247e54',
  analiseUsuarios: '4f269d54-9193-4ad6-bdad-5f7de942d2c0',
  analiseComunicados: 'c99d0362-aeeb-4ca6-bbf1-4b2bd6181fe9',
  analiseProcurador: 'c6d3e7a8-ccaa-4b50-8c1d-f6b60bd3fa16',
  analiseEventos: '4427a4ff-78da-485e-8221-a3fa512fe8ce',
  analiseContribuintes: 'c9c51ae5-8d42-45e8-8344-acdd471232a3'
};
